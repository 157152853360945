// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/front_pc"

// import "stylesheets/thickbox"

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

require("src/common")
require("packs/sna_efriends.js")
require('jquery-ui/ui/effect')
require('jquery-ui/ui/effects/effect-highlight')
require("packs/smart_phone/jquery.flexslider-min")
require("src/smart_phone/styleswitch")
require("src/front")
require('packs/thickbox')
const images = require.context("../images", true)


